/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    a: "a",
    em: "em"
  }, _provideComponents(), props.components), {YouTube} = _components;
  if (!YouTube) _missingMdxReference("YouTube", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "A pesar de que en la industria se implantaron ya hace décadas los robots, sobre todo en la forma de brazos robots en la producción, en el hogar nunca han llegado a alcanzar un nivel más que testimonial. Los robots de limpieza encabezados por Roomba han sido el único intento con éxito de llevar un robot a cada casa y parece que se han estancado en esa funcionalidad. El principal obstáculo para la introducción de otros tipos de robots en los hogares es aunque parezca extraño, su dureza. Los robots tradicionales están pensados para las duras condiciones de la industria, son fuertes, pesados y resistentes, mucho más de lo que cualquier ser humano. Por esto la convivencia entre nosotros y los robots industriales es difícil, cualquier fallo en la programación puede resultar en una muerte y por eso siempre se delimitan zonas de seguridad donde los robots trabajan fuera del alcance de las personas que pueden pasar por ahí."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "images/20130626-roomba-cats-close-encounters-hero.jpg",
    alt: "",
    title: "Mi humano dice que esto es un robot. ¡Ya, claro!"
  }), "\nEn el hogar esta situación no se puede dar, robots y personas deben convivir unos con otros y es necesario crear robots “blandos” para este entorno. Hay varias lineas de investigación para este tipo de robots, una es utilizar las mismas técnicas utilizadas en robots industriales pero con materiales mucho más ligeros y técnicas de control que garantices movimientos suaves y poca fuerza que permiten a los humanos guiar a los robots e incluso enseñarles manualmente tareas."), "\n", React.createElement(_components.p, null, "La que vengo a explicar aquí es una nueva forma de construir robots blandos que ", React.createElement(_components.a, {
    href: "http://www.theengineer.co.uk/more-sectors/electronics/news/soft-bodied-robot-can-walk-through-fire/1019182.article"
  }, "está siendo estudiada ampliamente por la Universidad de Harvard"), ", podría explicarlo pero una imagen vale más que mil palabras y un vídeo son muchísimas imágenes, así que pegadle un vistazo a este:"), "\n", React.createElement(YouTube, {
    youTubeId: "uvCDYvHVK4Y"
  }), "\n", React.createElement(_components.p, null, "Vale, el vídeo y el prototipo dan  un poco de grima, pero es una tecnología en pañales, que puede hacer que empecemos a ver robots que puedan estar en nuestras casas, como habéis visto, con electrónica, aire comprimido y “musculos” de silicona."), "\n", React.createElement(_components.p, null, "Vale ¿Y esto va a ser otra innovación controlada por las empresas de electrodomésticos y con el código bien cerrado? Pues por ahora parece que no, ahora estamos a tiempo, antes de que llegue el ", React.createElement(_components.em, null, "hype"), ", podemos empezar a desarrollar nuestros primeros robots muy al estilo de Arduino, la Universidad de Harvard ha liberado un ", React.createElement(_components.a, {
    href: "http://softroboticstoolkit.com/home"
  }, "Soft Robotics Toolkit"), " con el que podemos empezar a experimentar, con una impresora 3D, un pequeño laboratorio de electrónica y algo de material podemos empezar a crear nuestras ", React.createElement(_components.a, {
    href: "http://softroboticstoolkit.com/book/sdm-fingers"
  }, "manos robot blandas"), " o lo que se nos ocurra."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
